<template>
	<v-row>
		<HomepageMenu :src="logoImage"></HomepageMenu>

		<v-col cols="4">
			<figure
				class="login__background secondary-contrast-background"
			></figure>
		</v-col>

		<v-col cols="8">
			<article class="login__form">
				<section class="login__form--wrapper">
					<figure class="text-center"></figure>
					<h1 class="login__form--title text-center primary-text">
						Activate your account
					</h1>
					<v-form ref="form">
						<v-text-field
							label="Password"
							v-model="userPassword"
							:append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
							:type="showPass1 ? 'text' : 'password'"
							name="input-10-1"
							hint="At least 5 characters"
							:rules="[rules.required]"
							@click:append="showPass1 = !showPass1"
						></v-text-field>
						<v-text-field
							label="Reenter Password"
							v-model="repeatUserPassword"
							:append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
							:type="showPass2 ? 'text' : 'password'"
							name="input-10-1"
							hint="At least 5 characters"
							:rules="[rules.required, rules.passwordMatch]"
							@click:append="showPass2 = !showPass2"
						></v-text-field>

						<v-btn
							class="mt-8 secondary-contrast-background contrast-text"
							block
							elevation="2"
							large
							:loading="isLoading"
							@click="register"
							>Create account</v-btn
						>

						<p class="text-center mt-5"></p
					></v-form>
					<FormMessage v-if="message" :type="status">
						{{ message }}</FormMessage
					>
					<router-link v-if="status === 'success'" to="/login">
						<p>Login</p>
					</router-link>
				</section>
			</article>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import HomepageMenu from "./components/HomepageMenu.vue";
import FormMessage from "../components/FormMessage";
import { post } from "@/util/requests/post";

export default {
	name: "RegisterView",
	components: { FormMessage, HomepageMenu },

	data() {
		return {
			showPass1: false,
			showPass2: false,
			userPassword: null,
			repeatUserPassword: null,
			isLoading: false,
			message: null,
			status: null,
			rules: {
				required: value => !!value || "Required.",
				counter: value => value.length <= 20 || "Max 20 characters",
				passwordMatch: v =>
					v === this.userPassword || "Password doesn't match",
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail.";
				}
			}
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async register() {
			this.message = "";

			try {
				if ((await this.validate()) === false) {
					this.message = "Check fields";
					return;
				}
				await this.$store.dispatch("setIsLoading", true);
				const response = await post(
					`/org_user_activate_by_invite_uuid/${this.userId}`,
					{
						password: this.userPassword
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.status = "success";
					this.message = "User successfully registered.";
				}
			} catch (error) {
				if (this.$route.query.userId == null) {
					this.message = "Please, enter correct user ID";
				} else {
					this.message = "An error occurred, please try again";
				}
				console.error(error);
				this.status = error.status;
			}
			await this.$store.dispatch("setIsLoading", false);
			// await this.$router.push({ name: "Login" });
		}
	},

	computed: {
		...mapGetters(["theme"]),
		logoImage() {
			return this.$store.state.organization.logo?.file?.public_url;
		},
		userId() {
			if (this.$route.query.userId === undefined) {
				return false;
			} else if (this.$route.query.userId !== undefined) {
				return this.$route.query.userId;
			} else {
				return false;
			}
		},
		organizationId() {
			if (this.$route.query.organizationId === undefined) {
				return 1;
			} else if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		}
	},

	async beforeMount() {
		localStorage.setItem("organizationId", this.organizationId);
		await this.$store.dispatch("getOrganizationInfo", this.organizationId);
		await this.$store.dispatch("setThemeColors");

		this.$store.dispatch("setIsThemeFetched", true);
	}
};
</script>
